import React from 'react'
import './Footer.css'

import logo from '../../assets/logo-new.png'

function Footer() {
    return (
        <div className='footer'>
            {window.innerWidth > 500 &&
                <div className='footerContainer' style={window.innerWidth < 500 ? { paddingTop: '30px' } : { paddingTop: '30px', maxWidth: '20%' }}>
                    <img loading="lazy" className='logoFooter' src={logo} alt='' />
                    <p className='subTextTable'>© 2024 - SELARL d'Orthodontie du Dr Irina-Elena Visan - Tous droits réservés</p>
                </div>
            }
            <div className='footerContainer' style={window.innerWidth < 500 ? { marginTop: '60px' } : { marginTop: '50px' }}>
                <h3 className='footerTitle'>Horaires</h3>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ textAlign: 'start' }}>
                        <p className='subTextTable'>Lundi</p>
                        <p className='subTextTable'>Mardi</p>
                        <p className='subTextTable'>Mercredi</p>
                        <p className='subTextTable'>Jeudi</p>
                        <p className='subTextTable'>Vendredi</p>
                        <p className='subTextTable'>Samedi</p>
                        <p className='subTextTable'>Dimanche</p>
                    </div>
                    <div style={{ textAlign: 'start', margin: '0 40px' }}>
                        <p className='subTextTable'>9h30-13h00</p>
                        <p className='subTextTable'>9h30-13h00</p>
                        <p className='subTextTable'>9h30-13h00</p>
                        <p className='subTextTable'>Fermé</p>
                        <p className='subTextTable'>9h30-13h00</p>
                        <p className='subTextTable'>Fermé</p>
                        <p className='subTextTable'>Fermé</p>
                    </div>
                    <div style={{ textAlign: 'start' }}>
                        <p className='subTextTable'>14h00-19h00</p>
                        <p className='subTextTable'>14h00-19h00</p>
                        <p className='subTextTable'>14h00-19h00</p>
                        <p className='subTextTable'>13h30-19h00</p>
                        <p className='subTextTable'>Fermé</p>
                        <p className='subTextTable'>Fermé</p>
                        <p className='subTextTable'>Fermé</p>
                    </div>
                </div>
            </div>
            <div className='footerContainer'>
                <h3 className='footerTitle'>Nous trouver</h3>
                <div style={{ textAlign: 'start' }}>
                    <p className='subTextTable'>Place de la Mairie</p>
                    <p className='subTextTable'>36250 Saint-Maur</p>
                    <p className='subTextTable' style={{ margin: '10px 0 0' }}>Tél : 02 54 35 71 01</p>
                    <div style={{ display: 'flex', marginTop: '20px', flexWrap: 'wrap' }}>
                        <a href="https://maps.app.goo.gl/n6BGwmeQNSThkgqDA" rel="noreferrer" target="_blank" className='buttonFooter'><p>NOUS LOCALISER</p></a>
                        {window.innerWidth < 500 && <a href="tel:0254357101" rel="noreferrer" target="_blank" className='buttonFooter'><p>NOUS APPELER</p></a>}
                    </div>
                </div>
            </div>
            {window.innerWidth < 500 &&
                <div className='footerContainer' style={{ paddingTop: '0px', paddingBottom: '30px', minWidth: '20%' }}>
                    <img loading="lazy" className='logoFooter' src={logo} alt='' />
                    <p className='subTextTable'>© 2024 - SELARL d'Orthodontie du Dr Irina-Elena Visan - Tous droits réservés</p>
                </div>
            }

        </div>
    )
}

export default Footer