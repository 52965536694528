import Navbar from './components/Navbar/Navbar';
import { Routes, Route } from "react-router-dom";
import './App.css';
import { useEffect, useState } from 'react';

// Screens
import Accueil from './components/Accueil/Accueil';
import Cabinet from './components/Cabinet/Cabinet';
import Soins from './components/Soins/Soins';
import Footer from './components/Footer/Footer';

function App() {
  const [menu, setMenu] = useState(false)
  const [currentScreen, setCurrentScreen] = useState(null)

  useEffect(() => {
    setCurrentScreen(window.location.pathname)
  }, [])

  return (
    <div className="App">
      <Navbar menu={menu} setMenu={setMenu} setCurrentScreen={setCurrentScreen} currentScreen={currentScreen} />

      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/accueil" element={<Accueil />} />
        <Route path="/cabinet" element={<Cabinet />} />
        <Route path="/soins" element={<Soins />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
