import React from 'react'
import { Link } from "react-router-dom";
import 'animate.css';
import './Navbar.css'

import phone from '../../assets/phone.png'
import gps from '../../assets/gps.png'
import logo from '../../assets/logo-new.png'

function Navbar(props) {
    return (
        <div className='navbar'>
            <div className='navbarSideContainer' style={{ padding: '0 10px' }}>
                <Link onClick={() => props.setCurrentScreen('/accueil')} className='link' to={'/accueil'}><img loading="lazy" className='logo' src={logo} alt='' /></Link>
            </div>
            <div className='navbarCenterContainer'>
                {props.currentScreen === '/' || props.currentScreen === '/accueil' ?
                    <div className='navTitle'>
                        <Link onClick={() => props.setCurrentScreen('/accueil')} className='link' style={{ marginBottom: '5px' }} to={'/accueil'}>ACCUEIL</Link>
                        <span className='underline' />
                    </div>
                    :
                    <div className="hover-underline-animation">
                        <Link onClick={() => props.setCurrentScreen('/accueil')} className='link' to={'/accueil'}>ACCUEIL</Link>
                    </div>
                }
                {props.currentScreen === '/cabinet' ?
                    <div className='navTitle'>
                        <Link onClick={() => props.setCurrentScreen('/cabinet')} className='link' style={{ marginBottom: '5px' }} to={'/cabinet'}>LE CABINET</Link>
                        <span className='underline' />
                    </div>
                    :
                    <div className="hover-underline-animation">
                        <Link onClick={() => props.setCurrentScreen('/cabinet')} className='link' to={'/cabinet'}>LE CABINET</Link>
                    </div>
                }
                {
                    props.currentScreen === '/soins' ?
                        <div className='navTitle'>
                            <Link onClick={() => props.setCurrentScreen('/soins')} className='link' style={{ marginBottom: '5px' }} to={'/soins'}>LES SOINS</Link>
                            <span className='underline' />
                        </div >
                        :
                        <div className="hover-underline-animation">
                            <Link onClick={() => props.setCurrentScreen('/soins')} className='link' to={'/soins'}>LES SOINS</Link>
                        </div>
                }
            </div >
            <div className='navbarSideContainer'>
                <a href="https://maps.app.goo.gl/n6BGwmeQNSThkgqDA" rel="noreferrer" target="_blank"><div className='buttonFlashy'><img className='buttonImg' src={gps} alt="" /><p>NOUS TROUVER</p></div></a>
                {window.innerWidth < 500 && <a href="tel:0254357101" rel="noreferrer" target="_blank"><div className='buttonFlashy'><img className='buttonImg' src={phone} alt="" /></div></a>}
            </div>
        </div >
    )
}

export default Navbar