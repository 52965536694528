import React, { useEffect, useState } from 'react'
import './Cabinet.css'

// Import pics
import chaise2 from '../../assets/chaise-2.jpg'

import sparkling from '../../assets/sparkling.png'
import smile from '../../assets/smile.png'
import team from '../../assets/team.png'

import cabinetExterior from '../../assets/cabinet-exterior.jpg'
import attente from '../../assets/attente.jpg'
import work from '../../assets/work.jpg'
import plaque from '../../assets/plaque.jpg'
import camera from '../../assets/camera-2.JPG'
import accueil from '../../assets/accueil-single.jpg'
import carte from '../../assets/carte.jpg'
import steLarge from '../../assets/ste-large.jpg'

function Cabinet(props) {
    const [zoom, setZoom] = useState(null)

    useEffect(() => {
        //Effects
        const highlight = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('highlightPurple');
                }
            });
        });
        highlight.observe(document.querySelector('.highlightText'));
    }, [])

    return (
        <div>
            <div className='container' style={window.innerWidth < 500 ? { marginTop: '0', marginBottom: '0' } : { marginTop: '50px', marginBottom: 0 }}>
                <h1 className='title'>Un cabinet rénové et équipé, <br /><span className='highlightText'>où on prend soin de vos dents</span></h1>

                <img className='coverPic' src={chaise2} alt="" />

                <div className='itemsOverlay'>
                    <div className='itemContainer'>
                        <img className='infoImg' loading="lazy" src={sparkling} alt='' />
                        <p className='infoText'>Techniques<br />modernes</p>
                    </div>
                    <div className='itemContainer'>
                        <img className='infoImg' loading="lazy" style={{ filter: 'grayscale(1)' }} src={smile} alt='' />
                        <p className='infoText'>Le sourire<br />qui vous ressemble</p>
                    </div>
                    <div className='itemContainer'>
                        <img className='infoImg' loading="lazy" src={team} alt='' />
                        <p className='infoText'>Une équipe<br />à votre écoute</p>
                    </div>
                </div>
            </div>
            <div className='container' style={window.innerWidth < 500 ? { marginTop: '50px', marginBottom: '40px' } : { marginTop: '-80px' }}>
                <h1 className='descTitle'>Le cabinet</h1>
                <div style={{ margin: '40px 0' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <img onClick={() => setZoom(work)} className="friseImg" loading="lazy" src={work} alt="" />
                        <img onClick={() => setZoom(cabinetExterior)} className="friseImg" loading="lazy" src={cabinetExterior} alt="" />
                        <img onClick={() => setZoom(attente)} className="friseImg" loading="lazy" src={attente} alt="" />
                        <img onClick={() => setZoom(camera)} className="friseImg" loading="lazy" src={camera} alt="" />
                        <img onClick={() => setZoom(accueil)} className="friseImg" loading="lazy" src={accueil} alt="" />
                        <img onClick={() => setZoom(plaque)} className="friseImg" loading="lazy" src={plaque} alt="" />
                        <img onClick={() => setZoom(carte)} className="friseImg" loading="lazy" src={carte} alt="" />
                        <img onClick={() => setZoom(steLarge)} className="friseImg" loading="lazy" src={steLarge} alt="" />
                    </div>
                </div>
            </div>
            {zoom != null && window.innerWidth < 500 &&
                <div className='zoomBgd' onClick={() => setZoom(null)}>
                    <img className="zoomImg" src={zoom} alt="" />
                </div>
            }
        </div>
    )
}

export default Cabinet