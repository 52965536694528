import React, { useEffect } from 'react'
import './Soins.css'

import bracesLogo from '../../assets/bracesLogo.png'
import aligners from '../../assets/aligners.png'
import bilan from '../../assets/bilan.png'
import hand from '../../assets/hand.jpg'
import radio from '../../assets/radio.jpg'
import famille from '../../assets/famile.jpg'

import one from '../../assets/one.png'
import two from '../../assets/two.png'
import three from '../../assets/three.png'
import four from '../../assets/four.png'
import five from '../../assets/five.png'


function Soins(props) {
    useEffect(() => {
        //Effects
        const highlight = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('highlightGold');
                }
            });
        });
        highlight.observe(document.querySelector('.highlightText'));
    }, [])

    return (
        <div >
            <div className='container' style={window.innerWidth < 500 ? { marginTop: '0' } : { marginTop: '50px', marginBottom: 0 }}>
                <h1 className='title'>L'excellence, <br /><span className='highlightText'>au service de votre sourire</span></h1>
                <img className='coverPic' style={{ objectPosition: 'top' }} src={radio} alt="" />
                <div className='itemsOverlay'>
                    <div className='itemContainer'>
                        <img className='infoImg' loading="lazy" style={{ filter: 'grayscale(1)' }} src={bracesLogo} alt='' />
                        <p className='infoText'>Systèmes<br />multi-attaches</p>
                    </div>
                    <div className='itemContainer'>
                        <img className='infoImg' loading="lazy" style={{ filter: 'grayscale(1)' }} src={aligners} alt='' />
                        <p className='infoText'>Gouttières<br />transparentes</p>
                    </div>
                    <div className='itemContainer'>
                        <img className='infoImg' loading="lazy" src={bilan} alt='' />
                        <p className='infoText'>Bilans<br />orthodontiques</p>
                    </div>
                </div>
            </div>
            <div className='container' style={window.innerWidth < 500 ? { borderRadius: '0 50px 50px 0', backgroundColor: '#b9cfbf74', marginTop: '180px', padding: '50px' } : { width: '75%', borderRadius: '0 50px 50px 0', backgroundColor: '#b9cfbf74', marginTop: '0', padding: '50px', flexWrap: 'nowrap' }}>
                {window.innerWidth < 500 && <img className='subImg' loading="lazy" src={hand} alt="" />}
                <div className='subText'>
                    <p className='subtitle'>L'ORTHODONTIE</p>
                    <h1 className='descTitle'>Une spécialité</h1>
                    <p style={{ lineHeight: '1.8em', opacity: 0.9 }}>L’Orthodontie est la spécialité médicale qui s’attache à corriger les anomalies de position des dents et/ou des mâchoires, afin de prévenir les désordres fonctionnels et esthétiques qui peuvent en être les conséquences. Parmi les objectifs d'un traitement orthodontique : des dents bien alignées, s’enclenchant le mieux possible entre elles, ainsi que des mâchoires harmonisées au mieux avec le reste de la face.</p>
                </div>
                {window.innerWidth > 500 && <img className='subImg' loading="lazy" src={hand} alt="" />}
            </div>
            <div className='container' style={window.innerWidth < 500 ? { padding: '40px' } : { padding: '40px', flexWrap: 'nowrap' }}>
                {window.innerWidth > 500 && <img loading="lazy" className='irinaImg' style={{ marginTop: '40px' }} src={famille} alt='' />}
                <div>
                    <p className='subtitle' style={{ width: '100%' }}>TRAITEMENT ORTHODONTIQUE</p>
                    <h1 className='descTitle'>Votre parcours de soins</h1>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginTop: '20px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0 8px 0' }}>
                            <img loading="lazy" style={{ width: '35px', marginRight: '8px' }} src={one} alt="" />
                            <p className='planText'>Première Consultation : <span style={{ fontWeight: 'normal' }}>examen clinique et discussion de vos objectifs</span></p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
                            <img loading="lazy" style={{ width: '35px', marginRight: '8px' }} src={two} alt="" />
                            <p className='planText'>Bilan complet : <span style={{ fontWeight: 'normal' }}>radiographies, photographies, empreintes numériques 3D et définition du traitement adéquat</span></p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
                            <img loading="lazy" style={{ width: '35px', marginRight: '8px' }} src={three} alt="" />
                            <p className='planText'>Plan de traitement : <span style={{ fontWeight: 'normal' }}>présentation des appareils, durée de traitement et coûts associés</span></p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
                            <img loading="lazy" style={{ width: '35px', marginRight: '8px' }} src={four} alt="" />
                            <p className='planText'>Traitement : <span style={{ fontWeight: 'normal' }}>pose des appareils, activations et RDVs de suivi</span></p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
                            <img loading="lazy" style={{ width: '35px', marginRight: '8px' }} src={five} alt="" />
                            <p className='planText'>Maintien des résultats : <span style={{ fontWeight: 'normal' }}>contention et RDVs de surveillance</span></p>
                        </div>
                    </div>
                </div>
                {window.innerWidth < 500 && <img loading="lazy" className='irinaImg' style={{ marginTop: '40px' }} src={famille} alt='' />}
            </div>

        </div>
    )
}

export default Soins