import React, { useEffect, useRef } from 'react'
import './Accueil.css'
import GoogleMap from 'google-maps-react-markers'

// Import pics
import coverPic from '../../assets/cover-pic.jpg'
import chaise from '../../assets/chaise.png'
import techno from '../../assets/techno.png'
import relation from '../../assets/relation.png'
import marker from '../../assets/marker.png'
import accueil from '../../assets/accueil.jpg'
import ste from '../../assets/ste.JPG'

import phone from '../../assets/phone.png'
import gps from '../../assets/gps.png'

function Accueil(props) {
    const mapRef = useRef(null)

    const mapProps = {
        center: {
            lat: 46.80564767196337,
            lng: 1.6395278515351763
        },
        zoom: 16
    };

    const onGoogleApiLoaded = ({ map, maps }) => {
        mapRef.current = map
    }

    useEffect(() => {
        const highlight = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('highlight');
                }
            });
        });
        highlight.observe(document.querySelector('.highlightText'));

        if (window.location.pathname === '/') {
            //Effects
            const bounce = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('bounce');
                    }
                });
            });
            const bounceInUp = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('bounceInUp');
                    }
                });
            });
            const fadeIn = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fadeIn');
                    }
                });
            });
            const fadeInRight = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fadeInRight');
                    }
                });
            });
            bounce.observe(document.querySelector('.title'));
            bounceInUp.observe(document.querySelector('.coverPic'));
            fadeIn.observe(document.querySelector('.itemsOverlay'));
            fadeInRight.observe(document.querySelector('.subImg'));
        }
    }, [])

    return (
        <div>
            <div className='container' style={window.innerWidth < 500 ? { marginTop: '0' } : { marginTop: '50px', marginBottom: 0 }}>
                <h1 className='title'>Bienvenue au cabinet d'orthodontie du <br /><span className='highlightText'>Dr Irina-Elena Visan</span></h1>

                <img className='coverPic' src={coverPic} alt="" />

                <div className='itemsOverlay'>
                    <div className='itemContainer'>
                        <img className='infoImg' loading="lazy" src={chaise} alt='' />
                        <p className='infoText'>Orthodontie enfants<br /> & adultes</p>
                    </div>
                    <div className='itemContainer'>
                        <img className='infoImg' loading="lazy" src={techno} alt='' />
                        <p className='infoText'>Technologies<br /> de pointe</p>
                    </div>
                    <div className='itemContainer'>
                        <img className='infoImg' loading="lazy" src={relation} alt='' />
                        <p className='infoText'>Service<br />personnalisé</p>
                    </div>
                </div>
            </div>
            <div className='container' style={window.innerWidth < 500 ? { borderRadius: '0 50px 50px 0', backgroundColor: '#b9cfbf74', marginTop: '180px', padding: '50px' } : { width: '75%', borderRadius: '0 50px 50px 0', backgroundColor: '#b9cfbf74', marginTop: '0', padding: '50px', flexWrap: 'nowrap' }}>
                {window.innerWidth < 500 && <img className='subImg' src={accueil} alt="" />}
                <div className='subText'>
                    <p className='subtitle'>SOINS D'ORTHODONTIE</p>
                    <h1 className='descTitle'>Le cabinet</h1>
                    <p className='descText'>Toute notre équipe est heureuse de vous accueillir au sein du cabinet d'orthodontie de Saint-Maur. Nous sommes à votre service, dans un cadre chaleureux et agréable, pour vous proposer les diagnostics et les traitements les plus évolués de l'orthodontie et orthopédie dento-faciale moderne : bilans radiographiques, empreintes 3D, appareils dentaires, gouttières transparentes… Nous vous accueillons sur rendez-vous tous les jours de la semaine.</p>
                </div>
                {window.innerWidth > 500 && <img className='subImg' src={accueil} alt="" />}
            </div>
            <div className='container' style={window.innerWidth < 500 ? { padding: '40px' } : { padding: '40px', flexWrap: 'nowrap' }}>
                <img className='irinaImg' src={ste} alt='' />
                <div className='subText'>
                    <p className='subtitle'>SPECIALISTE QUALIFIEE EN ORTHOPEDIE DENTO-FACIALE</p>
                    <h1 className='descTitle'>Votre praticienne</h1>
                    <p className='descText' style={{ marginBottom: '5px' }}>Le Docteur Visan est chirurgien-dentiste spécialiste qualifiée en orthodontie et orthopédie dento-faciale (ODF). Elle traite tous types de pathologies chez l'enfant et l'adulte, privilégiant les traitements alliant fonctionnel et esthétique pour des résultats équilibrés et durables. </p>
                    <p className='descText'>Le Docteur Visan continue de se former régulièrement pour pouvoir vous proposer, à vous et vos enfants, les dernières techniques de l'orthodontie pour des traitements efficaces et moins contraignants.</p>
                    <div style={{ textAlign: 'end' }}>
                        <h3 style={{ fontFamily: 'Cormoran', fontWeight: '200', opacity: 0.7, fontSize: '27px', fontStyle: 'italic', marginBottom: '10px' }}>"Votre sourire est votre meilleur atout."</h3>
                        <p className='subtitle'>Dr Irina-Elena Visan</p>
                    </div>
                </div>
            </div>
            <div className='container' style={window.innerWidth < 500 ? { padding: 0, backgroundColor: '#b9cfbf74', marginBottom: 0 } : { backgroundColor: '#b9cfbf74', padding: 0, marginBottom: 0, flexWrap: 'nowrap' }}>
                <div className='subText' style={{ padding: '60px' }}>
                    <p className='subtitle'>OU NOUS TROUVER</p>
                    <h1 className='descTitle'>Au coeur de Saint-Maur</h1>
                    <p style={{ lineHeight: '1.8em', opacity: 0.9, marginBottom: '5px' }}>Le cabinet d'orthodontie se situe en plein centre de Saint-Maur, dans le bâtiment de l'ancienne Poste en face de la Mairie. Il est facilement accessible depuis Châteauroux et le reste du territoire. Un parking gratuit est à votre disposition juste en face du cabinet.</p>
                    <div style={{ width: '100%', margin: '20px 0' }}>
                        <div>
                            <p style={{ fontFamily: 'Catamaran', fontWeight: 'bold', margin: '0' }}>Notre adresse</p>
                            <p style={{ fontSize: '0.95em', lineHeight: '1.6em', opacity: 0.9, margin: '0' }}>Place de la Mairie</p>
                            <p style={{ fontSize: '0.95em', lineHeight: '1.6em', opacity: 0.9, margin: '0' }}>36250 Saint-Maur</p>
                        </div>
                        <div style={{ display: 'flex', margin: '10px 0 0 0' }}>
                            <a href="https://maps.app.goo.gl/n6BGwmeQNSThkgqDA" rel="noreferrer" target="_blank"><div className='buttonFlashy' style={{ marginLeft: '0px' }}><img className='buttonImg' src={gps} alt="" /><p>S'Y RENDRE</p></div></a>
                            {window.innerWidth < 500 && <a href="tel:0254357101" rel="noreferrer" target="_blank"><div className='buttonFlashy'><img className='buttonImg' src={phone} alt="" /><p>NOUS APPELER</p></div></a>}
                        </div>
                    </div>
                </div>
                <div className='googleMaps'>
                    <GoogleMap
                        apiKey='AIzaSyAEpVYQweR671gIgoTQhHD7HzhYWsCmAOk'
                        defaultCenter={mapProps.center}
                        onGoogleApiLoaded={onGoogleApiLoaded}
                        defaultZoom={mapProps.zoom}
                        options={{
                            streetViewControl: false,
                            scaleControl: false,
                            overviewMapControl: false,
                            mapTypeControl: false,
                            clickableIcons: false,
                            fullscreenControl: false,
                            zoomControl: true
                        }}>
                        <Marker
                            lat={46.80569501138493}
                            lng={1.639472453614581}
                        />
                    </GoogleMap>
                </div>
            </div>
        </div>
    )
}

function Marker(props) {
    return (
        <div>
            <a href="https://maps.app.goo.gl/n6BGwmeQNSThkgqDA" rel="noreferrer" target="_blank" ><img className='markerImg' src={marker} alt="" /></a>
        </div >
    )
}


export default Accueil